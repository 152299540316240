import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isObjectLike from 'lodash/isObjectLike';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import omit from 'lodash/omit';
import startsWith from 'lodash/startsWith';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import SearchBar from '../../../core/components/SearchBar';
import { getKeyFromEnum, toString, formatStringToDate } from '../../../core/utils';
import { selectApiRequests } from '../../../store/apiRequestReducer';
import { selectSession, selectSite } from '../../../store/authReducer';
import { fetchEmployees } from '../../employees/actions';
import { selectEmployees } from '../../employees/reducer';
import { completeTaskCompletion, snoozeTaskCompletion } from '../../overview/actions';
import { selectSiteExtId, selectSiteTotalCarCount } from '../../settings/reducer';
import { selectMultiSiteSelection, selectSites } from '../../site-selection/reducer';
import * as actions from '../actions';
import TodaysTaskInDetail from '../components/TodaysTaskInDetail';
import { buildQuery, getLastCompleted, getTodaysTaskCompletionsDefaultQuery } from '../helpers';
import { Interval, TaskType } from '../models';
import {
  selectSelectedTasks,
  selectTaskInEdit,
  selectTaskLocations,
  selectTodaysTaskCompletions,
  selectTodaysTaskCompletionsPage,
  selectTodaysTaskCompletionsPageSize,
  selectTodaysTaskCompletionsTotal,
} from '../reducer';
import { TaskCompletionStatus } from '../types';
import TaskLocationFilter from './TaskLocationFilter';
import TaskPrefixFilter from './TaskPrefixFilter';
import TaskTypeFilter from './TaskTypeFilter';
import Loader from '../../../core/components/Loader';
import EmployeeDropdown from '../../../core/components/EmployeeDropdown';
import { Drawer, DrawerContainer, DrawerControls } from '../../../core/components/Drawer';
import FilterSelector from '../../../core/components/FilterSelector';

export class TodaysTasks extends Component {
  static propTypes = {
    site: PropTypes.number,
    intl: PropTypes.shape({}).isRequired,
    completed: PropTypes.bool,
    taskCompletions: PropTypes.arrayOf(PropTypes.shape({})),
    taskLocations: PropTypes.arrayOf(PropTypes.shape({})),
    getTodaysTaskCompletions: PropTypes.func.isRequired,
    fetchCompletionLogs: PropTypes.func.isRequired,
    completeTaskCompletion: PropTypes.func.isRequired,
    snoozeTaskCompletion: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    fetchEmployees: PropTypes.func.isRequired,
    employees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
    totalCarCount: PropTypes.number,
    match: PropTypes.shape({
      params: PropTypes.shape({
        site: PropTypes.string,
      }),
    }),
    selectedTasks: PropTypes.shape({}).isRequired,
    setTaskSelections: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  };

  state = {
    selectedTask: {},
    sortQuery: {},
    notesDraft: {},
    taskInEditExpanded: false,
    highlightedRow: 0,
    currentEmployeeId: 'All',
    searchResults: null,
    searchResultsDetails: [],
    searchText: '',
    typeFilter: '',
    prefixFilter: '',
    locationFilter: '',
  };

  getTaskQuery = (queryParameters = {}) => {
    const defaultQuery = getTodaysTaskCompletionsDefaultQuery();
    return buildQuery(defaultQuery, queryParameters, this.state);
  };

  componentDidMount() {
    this.getTodaysTasks();
    this.props.fetchEmployees();

    if (this.props.employees.length) {
      this.setDefaultCurrentEmployee();
    }
  }

  shouldComponentUpdate(nextProp, nextState) {
    const omitedNextState = omit(nextState, 'notesDraft');
    const omitedState = omit(this.state, 'notesDraft');
    if (isEqual(nextProp, this.props) && isEqual(omitedState, omitedNextState)) {
      return false;
    }

    return true;
  }

  checkFilterChange = (prevState) => {
    const { typeFilter, prefixFilter, locationFilter } = this.state;

    const typeFilterChanged = typeFilter !== prevState.typeFilter;
    const prefixFilterChanged = prefixFilter !== prevState.prefixFilter;
    const locationFilterChanged = locationFilter !== prevState.locationFilter;

    return typeFilterChanged || prefixFilterChanged || locationFilterChanged;
  };

  componentDidUpdate(prevProps, prevState) {
    const filterChanged = this.checkFilterChange(prevState);
    const sortQueryChanged = !isEqual(prevState.sortQuery, this.state.sortQuery);
    const employeesLoaded = !isEqual(prevProps.employees, this.props.employees);
    const newEmployeeSelected = !isEqual(prevState.currentEmployeeId, this.state.currentEmployeeId);
    const siteIdChanged = !isEqual(prevProps.primarySiteId, this.props.primarySiteId);
    const additionalSitesChanged = !isEqual(prevProps.multiSites, this.props.multiSites);
    const isEditTaskChanged = !isEqual(prevProps.isEditing, this.props.isEditing);
    const isFromDateChanged = !isEqual(
      prevProps?.asOfDateProps?.fromDate,
      this.props?.asOfDateProps?.fromDate
    );
    const isUntilDateChanged = !isEqual(
      prevProps?.asOfDateProps?.untilDate,
      this.props?.asOfDateProps?.untilDate
    );
    const isTaskTabChangedToCompleted = !isEqual(prevProps.completed, this.props.completed);

    if (
      filterChanged ||
      sortQueryChanged ||
      siteIdChanged ||
      additionalSitesChanged ||
      newEmployeeSelected ||
      isEditTaskChanged ||
      isFromDateChanged ||
      isUntilDateChanged ||
      isTaskTabChangedToCompleted ||
      this.props.isDeleting
    ) {
      this.getTodaysTasks();
    }
    if (employeesLoaded) {
      this.setDefaultCurrentEmployee();
    }
    if (isEditTaskChanged || isTaskTabChangedToCompleted) {
      this.setState({ toggleCollapse: false });
    }
    if (isTaskTabChangedToCompleted) {
      this.setState({ selectedTask: {} });
    }
  }

  getTodaysTasks = () => {
    const taskQuery = this.getTaskQuery();
    this.props.getTodaysTaskCompletions(taskQuery);
  };

  getDue = (taskCompletion) => {
    if (taskCompletion.task.interval === toString(Interval, Interval.CarCount)) {
      return {
        due: taskCompletion.scheduleCarCount - this.props.totalCarCount,
        highlight: this.props.totalCarCount >= taskCompletion.scheduleCarCount,
      };
    }

    const due = taskCompletion.scheduleEnd
      ? moment(taskCompletion.scheduleEnd)
      : moment(taskCompletion.scheduleStart);

    const highlight = moment().isAfter(due);

    if (taskCompletion.task.interval === toString(Interval, Interval.Hourly)) {
      return {
        due: moment().format('h a'),
        highlight,
      };
    }

    return { due, highlight };
  };

  getLocation = (taskCompletion) => {
    const location = this.props.taskLocations.find(
      (loc) => loc.type === taskCompletion.task.locationId
    );
    return location ? location.message : '';
  };

  setSelectedRows = (rows) => {
    const selectRow = (rowList, rowData) => {
      if (rowList[rowData._id]) {
        return omit(rowList, [rowData._id]);
      }
      return { ...rowList, [rowData._id]: rowData };
    };

    const selectAll = (rowList, selectedList) => {
      if (Object.keys(rowList).length !== selectedList.length) {
        return keyBy(selectedList, '_id');
      }
      return {};
    };

    let currentSelections = this.props.selectedTasks;

    if (rows.length) {
      currentSelections = selectAll(currentSelections, rows);
    } else {
      currentSelections = selectRow(currentSelections, rows);
    }

    this.props.setTaskSelections(currentSelections);
  };

  setSearchMatch = (searchResults, searchResultsDetails, searchText) => {
    this.setState({
      searchResults,
      searchResultsDetails,
      searchText,
    });
  };

  setEmployeeSelectedState = (value) => {
    if (value === 'All') {
      this.setState({
        currentEmployeeId: '',
      });
    } else {
      this.setState({
        currentEmployeeId: Number(value),
      });
    }
  };

  setDefaultCurrentEmployee = () => {
    this.setState({
      currentEmployeeId: this.props.employees[0].id,
    });
  };

  handleDone = (event, taskCompletions) => {
    const taskIds =
      isObjectLike(taskCompletions) && !taskCompletions._id
        ? map(taskCompletions, '_id')
        : taskCompletions._id;

    const dialogProps = {
      onReady: this.props.completeTaskCompletion,
      taskCompletion: {
        taskIds,
        voidCallback: this.getTodaysTasks,
        notes: this.state.notesDraft,
      },
      currentEmployeeId: this.state.currentEmployeeId,
      title: this.props.intl.formatMessage({ id: 'taskCompletion' }),
      status: TaskCompletionStatus.Completed,
    };

    event.stopPropagation();

    this.props.showDialog('TODAYS_TASKS_DIALOG', dialogProps);
  };

  handleSnooze = (event, taskCompletions) => {
    const taskIds =
      isObjectLike(taskCompletions) && !taskCompletions._id
        ? map(taskCompletions, '_id')
        : taskCompletions._id;

    const dialogProps = {
      onReady: this.props.snoozeTaskCompletion,
      taskCompletion: {
        taskIds,
        voidCallback: this.getTodaysTasks,
      },
      currentEmployeeId: this.state.currentEmployeeId,
      title: this.props.intl.formatMessage({ id: 'snoozeTask' }),
      status: TaskCompletionStatus.Snoozed,
    };

    event.stopPropagation();

    this.props.showDialog('TODAYS_TASKS_DIALOG', dialogProps);
  };

  dueFormat = (value, highlighted) => {
    const formattedValue = Number.isInteger(value)
      ? Math.abs(value).toLocaleString('en-US')
      : value;

    if (highlighted && Number.isInteger(value)) {
      return `+${formattedValue}`;
    }
    return value;
  };

  fetchSortedTodaysTasks = (sortParameters) => {
    if (sortParameters) {
      this.setState({ sortQuery: sortParameters });
    }
  };

  rowClickFunction = (data) => {
    const keys = Object.keys(this.props.selectedTasks);
    if (keys.length > 0) {
      if (keys.find((key) => parseInt(key) === data._id)) {
        this.setState((state) => ({
          selectedTask: { ...state.selectedTask, ...data },
        }));
        return;
      }
    }
    this.setState((state) => ({
      selectedTask: { ...state.selectedTask, ...data },
      highlightedRow: data._id,
    }));
  };

  getRowClickInfo = () => ({
    callback: (data) => {
      this.rowClickFunction(data);
    },
  });

  formatEmployeeName = (taskCompletion) => {
    const employee = this.props.employees.find((e) => e.id === taskCompletion.employeeId);
    return employee ? `${employee.firstName} ${employee.lastName}` : '';
  };

  getTaskSiteName = (task) => {
    let site;
    if (task?.siteId) {
      site = this.props.siteList.find((s) => s.id === task.siteId);
    } else {
      site = this.props.siteList.find((s) => s.id === task.task.siteId);
    }
    return site ? site.name : '';
  };

  getEstimatedDueDate = (taskCompletion) => {
    const dueData = this.getDue(taskCompletion);
    const due = moment.isMoment(dueData.due)
      ? dueData.due.format('L')
      : this.dueFormat(dueData.due, dueData.highlight);

    let estimatedDate = '';
    if (taskCompletion?.task) {
      if (
        taskCompletion.task.interval === getKeyFromEnum(Interval, Interval.CarCount) &&
        taskCompletion.task?.forecast?.length
      ) {
        estimatedDate = formatStringToDate(String(taskCompletion.task.forecast[0].forecastedDate));
      }
    }

    return (
      <div>
        <div>{estimatedDate}</div>
        {due}
      </div>
    );
  };

  getTaskInterval = (taskCompletion) => {
    if (taskCompletion.task.interval === getKeyFromEnum(Interval, Interval.CarCount)) {
      return taskCompletion.scheduleCarCount;
    }

    return taskCompletion.task.interval;
  };

  initializeDatatable = (taskCompletions) => {
    const { intl } = this.props;

    const data = taskCompletions.map((taskCompletion) => {
      // If task is Interval.Once, it doesn't have scheduleEnd.
      // Use scheduleStart as due date instead.
      const dueData = this.getDue(taskCompletion);
      return {
        _id: taskCompletion.id,
        _taskId: taskCompletion.taskId,
        name: (
          <div>
            <div>{taskCompletion.task && taskCompletion.task.name}</div>
            <div className="task-subtitle">
              {taskCompletion.task &&
              taskCompletion.task.subTitle &&
              taskCompletion.task.subTitle.length > 50
                ? taskCompletion.task.subTitle.slice(0, 47) + '...'
                : taskCompletion.task.subTitle}
            </div>
          </div>
        ),
        interval: this.getTaskInterval(taskCompletion),
        due: this.getEstimatedDueDate(taskCompletion),
        _highlightInformation: {
          index: 2,
          highlight: dueData.highlight,
        },
        lastCompleted: getLastCompleted(taskCompletion, this.props.employees),
        _resource: taskCompletion.task.resource,
        location: taskCompletion.task && this.getLocation(taskCompletion),
      };
    });

    const headers = [
      { name: intl.formatMessage({ id: 'name' }), databaseProperty: 'name' },
      { name: intl.formatMessage({ id: 'interval' }), databaseProperty: 'interval' },
      {
        name: intl.formatMessage({ id: 'due' }),
        databaseProperty: 'scheduleEnd',
      },
      { name: intl.formatMessage({ id: 'lastCompleted' }), databaseProperty: 'timestamp' },
      {
        name: this.props.intl.formatMessage({ id: 'resource' }),
        cell: {
          component: (props) => {
            const url = startsWith(props.rowData._resource, 'http')
              ? props.rowData._resource
              : `https://${props.rowData._resource}`;
            return (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {props.rowData._resource && (
                  <i
                    className="icon icon-link px-4 text-info"
                    title={`${props.rowData._resource}`}
                  />
                )}
              </a>
            );
          },
        },
      },
      { name: intl.formatMessage({ id: 'location' }), databaseProperty: 'locationId' },
    ];

    return {
      data,
      headers,
      showLoader: this.props.isLoading,
      isLoading: this.props.isLoading,
      selectedRows: this.props.selectedTasks,
      rowClickInfo: this.getRowClickInfo(),
      rowInEdit: this.props.selectedTaskInEdit,
      fetchSortedData: this.fetchSortedTodaysTasks,
      multiSelectEnabled: true,
      updateSelectedRows: this.setSelectedRows,
      options: {
        isSelect: true,
      },
      toCSV: false,
    };
  };

  editTask = () => {
    const { id, isEssentialTask } = this.props.selectedTaskInEdit;
    const formatMessage = this.props.intl.formatMessage;

    const title = isEssentialTask
      ? formatMessage({ id: 'editEssentialTask' })
      : formatMessage({ id: 'editTask' });

    const onSubmit = (task) => {
      const saveProps = { task };

      if (isEssentialTask) {
        this.props.saveEssentialTask(saveProps);
      } else {
        this.props.saveTask(saveProps);
      }
    };

    this.props.showDialog('UPSERT_TASK', {
      title,
      onSubmit,
      isEssentialTask,
      isNew: false,
      modelYears: this.props.selectedTaskInEdit.modelYears,
    });
  };

  deleteTask = () => {
    const { id, name, isEssentialTask } = this.props.selectedTaskInEdit;
    const deletionParams = {
      id,
      callback: this.props.deleteTaskInEdit(),
    };

    const confirmProps = {
      title: this.props.intl.formatMessage({ id: 'archiveTask' }),
      text: this.props.intl.formatMessage({ id: 'taskArchiveConfirmation' }, { name }),
      onReady: isEssentialTask
        ? () => this.props.deleteEssentialTask(deletionParams)
        : () => this.props.deleteTask(deletionParams),
    };

    this.props.showDialog('CONFIRM_DIALOG', confirmProps);
    this.setState({ selectedTask: {}, taskInEditExpanded: false });
  };

  renderEmployees = () => (
    <select
      className="form-control"
      value={this.state.currentEmployeeId ?? 'all'}
      onChange={(e) => this.setEmployeeSelectedState(e)}
    >
      <option key={null} value={null}>
        {this.props.intl.formatMessage({ id: 'all' })}
      </option>
      {this.props.employees.map((e) => {
        const fullName = `${e.lastName}, ${e.firstName}`;

        return (
          <option key={e.id} value={e.id}>
            {fullName}
          </option>
        );
      })}
    </select>
  );

  setTypeFilter = (filter) => {
    const newFilters = { typeFilter: filter };

    if (filter !== 'Project') {
      newFilters.prefixFilter = '';
    }

    this.setState(newFilters);
  };

  setSelectedTask = (task) => {
    this.setState({ selectedTask: task });
  };

  removeSelectedTask = () => {
    this.setState({ selectedTask: {}, taskInEditExpanded: false });
  };

  setPrefixFilter = (filter) => {
    this.setState({ prefixFilter: filter });
  };

  setLocationFilter = (filter) => {
    this.setState({ locationFilter: filter });
  };

  setTaskInEditExpanded = () => {
    this.setState({ taskInEditExpanded: !this.state.taskInEditExpanded });
  };

  render() {
    const { taskCompletions, intl } = this.props;
    const data = this.state.searchResults !== null ? this.state.searchResults : taskCompletions;
    return (
      <Container data-testid="todays-tasks" fluid className="no-padding">
        <Row className="todays-tasks">
          <Col>
            <div className="todays-tasks-container">
              <div className="table-filters no-margin">
                <div className="form-group">
                  <SearchBar
                    intl={this.props.intl}
                    dataToSearchOn={this.props.taskCompletions}
                    dataToSearchFor={['task.name', 'task.type']}
                    setSearchMatch={this.setSearchMatch}
                    uniqueField="id"
                    styles={['data-table-search']}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="filters-actions-container">
              <FilterSelector
                filters={[
                  {
                    component: TaskTypeFilter,
                    props: {
                      selectedFilter: this.state.typeFilter,
                      setFilter: this.setTypeFilter,
                    },
                  },
                  {
                    component: TaskLocationFilter,
                    props: {
                      selectedFilter: this.state.locationFilter,
                      setFilter: this.setLocationFilter,
                    },
                  },
                  this.state.typeFilter === toString(TaskType, TaskType.Project) && {
                    component: TaskPrefixFilter,
                    props: {
                      selectedFilter: this.state.prefixFilter,
                      setFilter: this.setPrefixFilter,
                    },
                  },
                  this.props.employees.length > 0 && {
                    component: 'div',
                    props: {
                      children: (
                        <>
                          <label htmlFor="employeeId" className="form-check-label">
                            {this.props.intl.formatMessage({ id: 'teamMember' })}
                          </label>
                          <EmployeeDropdown
                            employees={this.props.employees}
                            currentEmployeeId={this.state.currentEmployeeId}
                            setEmployee={this.setEmployeeSelectedState}
                            intl={this.props.intl}
                          />
                        </>
                      ),
                    },
                  },
                ].filter(Boolean)}
                disabled={false}
                isLoading={false}
              />

              <div>
                <div className="page-action-buttons">
                  {!!Object.values(this.props.selectedTasks).length && (
                    <span data-testid="task-action-buttons">
                      <button
                        className="button blue mr-2"
                        data-testid="task-action-button"
                        disabled={!Object.keys(this.props.selectedTasks).length}
                        onClick={(e) => {
                          this.handleSnooze(e, this.props.selectedTasks);
                        }}
                      >
                        {this.props.intl.formatMessage({ id: 'snooze' })} (
                        {Object.keys(this.props.selectedTasks).length})
                      </button>
                      <button
                        className="button green"
                        data-testid="task-action-button"
                        disabled={!Object.keys(this.props.selectedTasks).length}
                        onClick={(e) => {
                          this.handleDone(e, this.props.selectedTasks);
                        }}
                      >
                        {this.props.intl.formatMessage({ id: 'done' })} (
                        {Object.keys(this.props.selectedTasks).length})
                      </button>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <DrawerContainer>
          <div>
            <DataTable
              showLoader={this.props.isLoading}
              highlightedRow={this.state.highlightedRow}
              scrolling
              {...this.initializeDatatable(data)}
            />
          </div>
          <Drawer
            expanded={this.state.selectedTask && this.state.selectedTask.hasOwnProperty('_id')}
            maximized={this.state.taskInEditExpanded}
          >
            {this.state.selectedTask && this.state.selectedTask.hasOwnProperty('_id') && (
              <TodaysTaskInDetail
                {...this.state.selectedTask}
                match={this.props.match}
                history={this.props.history}
                setSelectedTask={this.setSelectedTask}
                removeSelectedTask={this.removeSelectedTask}
                setTaskInEditExpanded={this.setTaskInEditExpanded}
                taskInEditExpanded={this.state.taskInEditExpanded}
              />
            )}
          </Drawer>
        </DrawerContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const apiRequests = selectApiRequests(state);
  const isLoading = get(apiRequests, `${actions.GET_TODAYS_TASK_COMPLETIONS}.isLoading`, false);
  const isDeleting = get(apiRequests, `${actions.DELETE_ESSENTIAL_TASK}.isLoading`, false);
  const isEditing = get(apiRequests, `${actions.SAVE_TASK}.isLoading`, false);

  return {
    site: Number(selectSite(state)),
    siteList: selectSites(state),
    total: selectTodaysTaskCompletionsTotal(state),
    page: selectTodaysTaskCompletionsPage(state),
    pageSize: selectTodaysTaskCompletionsPageSize(state),
    taskCompletions: selectTodaysTaskCompletions(state),
    totalCarCount: selectSiteTotalCarCount(state),
    session: selectSession(state),
    employees: selectEmployees(state),
    selectedTasks: selectSelectedTasks(state),
    selectedTaskInEdit: selectTaskInEdit(state),
    taskLocations: selectTaskLocations(state),
    primarySiteId: selectSiteExtId(state),
    multiSites: selectMultiSiteSelection(state),
    isLoading,
    isDeleting,
    isEditing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      showDialog,
      fetchEmployees,
      completeTaskCompletion,
      snoozeTaskCompletion,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl);

export default enhance(TodaysTasks);
