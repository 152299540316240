import { useState } from 'react';
import Fade from '../Fade';
import FilterMenu from './FilterMenu';

const FilterSelector = (props) => {
  const { filters = [] } = props;
  const [hide, setHide] = useState(true);
  const handleShowHide = () => {
    setHide(!hide);
  };
  return (
    <div className="drs-container">
      <div>
        <button
          data-testid="filtersMenu"
          className="btn btn-primary drs-button-override drs-button-shadow"
          onClick={handleShowHide}
        >
          <i className="uil uil-filter drs-mr-7"></i>
          Filters
        </button>
        <Fade hide={hide}>
          <FilterMenu
            filters={filters}
            handleShowHide={handleShowHide}
            additionalOptions={props.additionalOptions}
            removeItems={props.removeItems}
          />
        </Fade>
      </div>
    </div>
  );
};

FilterSelector.defaultProps = {
  disabled: false,
  isLoading: false,
};

export default FilterSelector;
