import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { injectIntl } from 'react-intl';
import EllipsisLoader from '../EllipsisLoader';
import SlideOut from '../SlideOut';

const FilterMenu = (props) => {
  const { filters } = props; // Get the dynamic filters array
  const cardWidth = 500;
  const phoneSize = 415;
  const menu = useRef(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const currentX = menu.current.getBoundingClientRect().x;
    const screenSize = window.innerWidth;
    if (cardWidth + currentX > screenSize) {
      const offsetValue = screenSize - (cardWidth + currentX);
      setOffset((prevValue) => prevValue + offsetValue);
    }
    if (screenSize <= phoneSize) {
      setOffset(0);
    }
  }, [menu.current, offset]);

  return (
    <div ref={menu} style={{ left: `${offset}px` }} className="drs-card">
      <div className="card-header">
        <h4 className="drs-card-header-title">Filters</h4>
        <button className="close float-right" onClick={props.handleShowHide}>
          <span>&times;</span>
        </button>
      </div>
      <div className="card-body drs-body-padding">
        <div className="table-filters">
          {filters.map((filter, index) => {
            const FilterComponent = filter.component;
            return <FilterComponent key={index} {...filter.props} />;
          })}
        </div>
      </div>
      <div className="card-body drs-body-padding--footer"></div>
    </div>
  );
};

export default injectIntl(FilterMenu);
