import _, { has, isEqual, join, keys, map, pickBy } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import { toString } from '../../../core/utils';
import DataTable from '../../../core/components/DataTable';
import { SortDirection } from '../../../core/components/DataTableHeaders';
import DateRangeSelector from '../../../core/components/DateRangeSelector';
import FilterSelector from '../../../core/components/FilterSelector';
import PageActions from '../../../core/components/PageActions';
import SearchBar from '../../../core/components/SearchBar';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { viewPermissionRefs } from '../../../core/components/UserGroupPermissions';
import * as utils from '../../../core/utils';
import { formatPhoneNumber } from '../../../utils/number-util';
import { fetchEmployees } from '../../employees/actions';
import { selectSiteExtId } from '../../settings/reducer';
import { selectMultiSiteSelection } from '../../site-selection/reducer';
import * as actions from '../actions';
import StatusFilter from '../components/StatusFilter';
import TypeFilter from '../components/TypeFilter';
import ArchivedFilter from '../components/ArchivedFilter';
import { ReclamationStatus, ReclamationType } from '../models';
import {
  selectFromDate,
  selectPage,
  selectPageSize,
  selectReclamationDamageOptions,
  selectReclamations,
  selectStatusFilter,
  selectTotal,
  selectTypeFilter,
  selectArchivedFilter,
  selectUntilDate,
} from '../reducer';
import get from 'lodash/get';
import { selectApiRequests } from '../../../store/apiRequestReducer';

export class Reclamations extends Component {
  static propTypes = {
    reclamations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.number.isRequired,
        status: PropTypes.number.isRequired,
      })
    ).isRequired,
    damageClaimOptions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        value: PropTypes.string,
        options: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            value: PropTypes.string,
          })
        ),
      })
    ),
    getReclamations: PropTypes.func.isRequired,
    fetchEmployees: PropTypes.func.isRequired,
    getDamageClaimOptions: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    intl: PropTypes.shape({}).isRequired,
    deleteReclamation: PropTypes.func.isRequired,
    setFromDate: PropTypes.func.isRequired,
    setUntilDate: PropTypes.func.isRequired,
    total: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    reclamationType: PropTypes.oneOf([ReclamationType.Rewash, ReclamationType.Claim]).isRequired,
    navigationInfo: PropTypes.shape({
      path: PropTypes.string,
    }),
    fromDate: PropTypes.shape({ format: PropTypes.func }),
    untilDate: PropTypes.shape({ format: PropTypes.func }),
    dateQuery: PropTypes.shape({
      fromDate: PropTypes.string,
      untilDate: PropTypes.string,
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        site: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    statusFilter: PropTypes.number.isRequired,
    typeFilter: PropTypes.number.isRequired,
    archivedFilter: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchResults: null,
      sortQuery: null,
    };
  }

  componentDidMount() {
    this.props.getDamageClaimOptions();
    this.props.getReclamations(this.getQuery());
    this.props.fetchEmployees();
  }

  componentDidUpdate(prevProps, prevState) {
    const datesChanged = !isEqual(prevProps.dateQuery, this.props.dateQuery);
    const sortQueryChanged = !isEqual(prevState.sortQuery, this.state.sortQuery);
    const filterChanged =
      prevProps.statusFilter !== this.props.statusFilter ||
      prevProps.archivedFilter !== this.props.archivedFilter ||
      prevProps.typeFilter !== this.props.typeFilter;
    const primarySiteChange = !isEqual(prevProps.siteId, this.props.siteId);
    const multiSiteSelectionChange = !isEqual(prevProps.multiSites, this.props.multiSites);
    if (
      filterChanged ||
      datesChanged ||
      primarySiteChange ||
      multiSiteSelectionChange ||
      sortQueryChanged
    ) {
      this.props.getReclamations(this.getQuery());
    }
  }

  getReclamationsPage = (page) => {
    this.props.getReclamations({ page, ...this.getQuery() });
  };

  getQuery = () => {
    const query = {
      types: utils.toString(ReclamationType, this.props.reclamationType),
      ...this.props.dateQuery,
    };

    if (this.props.reclamationType === ReclamationType.Claim) {
      query.statuses = this.getStatusInCorrectFormat();
      query.type = this.props.typeFilter;
      if (this.props.archivedFilter) {
        query.includeArchived = this.props.archivedFilter;
      }
      if (this.state.sortQuery) {
        if (this.state.sortQuery.key) {
          query.key = this.state.sortQuery.key;
        }
        if (!_.isNil(this.state.sortQuery.direction)) {
          query.direction = toString(SortDirection, this.state.sortQuery.direction);
        }
      }
    }

    Object.keys(query).forEach((key) => {
      if (query[key] === 0) {
        delete query[key];
      }
    });

    return query;
  };

  // eslint-disable-next-line
  setSearchMatch = (searchResults, searchResultsDetails, searchText) => {
    this.setState({
      searchResults,
    });
  };

  getStatusInCorrectFormat = () => {
    if (
      this.props.statusFilter === ReclamationStatus.AllOpen ||
      this.props.statusFilter === ReclamationStatus.AllClosed ||
      this.props.statusFilter === ReclamationStatus.All
    ) {
      return this.formatStatusFilterIntoArray(this.props.statusFilter);
    }

    return utils.toString(ReclamationStatus, this.props.statusFilter);
  };

  getPaginationInfo() {
    return {
      total: this.props.total,
      page: this.props.page,
      pageSize: this.props.pageSize,
      goToPage: this.getReclamationsPage,
    };
  }

  getDatatableHeaders() {
    const { intl } = this.props;

    if (this.props.reclamationType === ReclamationType.Rewash) {
      return [
        { name: intl.formatMessage({ id: 'date' }) },
        { name: intl.formatMessage({ id: 'time' }) },
        { name: intl.formatMessage({ id: 'operation' }) },
        { name: intl.formatMessage({ id: 'rewashReason' }) },
        {
          name: intl.formatMessage({ id: 'action' }),
          cell: {
            component: TableActionButtonsCell,
            mixIns: {
              buttons: [
                { icon: 'icon-trash', buttonAction: this.deleteReclamation },
                { icon: 'icon-edit', buttonAction: this.editReclamation },
              ],
            },
          },
        },
      ];
    }

    return [
      { name: intl.formatMessage({ id: 'claim' }) },
      { name: intl.formatMessage({ id: 'date' }) },
      { name: intl.formatMessage({ id: 'status' }) },
      { name: intl.formatMessage({ id: 'vehicle' }) },
      { name: intl.formatMessage({ id: 'type' }) },
      { name: intl.formatMessage({ id: 'location' }), databaseProperty: 'location' },
      { name: intl.formatMessage({ id: 'customerName' }) },
      { name: intl.formatMessage({ id: 'customerPhoneNumber' }) },
      { name: intl.formatMessage({ id: 'customerEmail' }) },
    ];
  }

  fetchSortedReclamations = (sortParameters) => {
    if (sortParameters) {
      this.setState({ sortQuery: sortParameters });
    }
  };

  getRewashReason(reclamation) {
    if (!has(reclamation, 'incidentDetails.needsRewash')) {
      return '';
    }
    const rewashReasons = keys(pickBy(reclamation.incidentDetails.needsRewash));
    return join(
      map(rewashReasons, (reasonName) => this.props.intl.formatMessage({ id: reasonName })),
      ', '
    );
  }

  getDatatableDatum = (reclamation) => {
    const claimOption = this.props.damageClaimOptions.find(
      (option) => option.id === reclamation.claimDamagePartId
    );
    const type = (claimOption && claimOption.value) || '';
    const status = utils.toString(ReclamationStatus, reclamation.status);
    let reclamationIdText = reclamation.id;
    if (reclamation.archivedAt != null) {
      reclamationIdText = reclamation.id + ' (archived)';
    }

    if (this.props.reclamationType === ReclamationType.Rewash) {
      return {
        _id: reclamation.id,
        _reclamationType: utils.toString(ReclamationType, reclamation.type),
        date: moment(reclamation.issueDate).format('MM/DD/YYYY'),
        time: moment(reclamation.issueDate).format('LT'),
        operation: reclamation.rewashOperation,
        rewashReason: this.getRewashReason(reclamation),
      };
    }

    return {
      _id: reclamation.id,
      _reclamationType: utils.toString(ReclamationType, reclamation.type),
      claim: reclamationIdText,
      date: moment(reclamation.issueDate).format('MM/DD/YYYY'),
      status: this.props.intl.formatMessage({ id: status }),
      vehicle: reclamation.vehicleDetails.plate,
      type,
      location: reclamation.issueSite.name,
      customerName: reclamation.customerName,
      customerPhone: formatPhoneNumber(reclamation.customerPhone),
      customerEmail: reclamation.customerEmail,
    };
  };

  formatStatusFilterIntoArray = (filter) => {
    const allOptions = {
      allOpen: [
        utils.toString(ReclamationStatus, ReclamationStatus.Incomplete),
        utils.toString(ReclamationStatus, ReclamationStatus.NeedsAttention),
        utils.toString(ReclamationStatus, ReclamationStatus.UnderRepair),
        utils.toString(ReclamationStatus, ReclamationStatus.NeedsRepair),
        utils.toString(ReclamationStatus, ReclamationStatus.Investigating),
      ],
      allClosed: [
        utils.toString(ReclamationStatus, ReclamationStatus.Repaired),
        utils.toString(ReclamationStatus, ReclamationStatus.Resolved),
        utils.toString(ReclamationStatus, ReclamationStatus.NotCaused),
        utils.toString(ReclamationStatus, ReclamationStatus.Denied),
        utils.toString(ReclamationStatus, ReclamationStatus.Completed),
      ],
    };
    if (filter === ReclamationStatus.All) {
      return [...allOptions.allOpen, ...allOptions.allClosed];
    }
    if (filter === ReclamationStatus.AllOpen) {
      return allOptions.allOpen;
    }
    return allOptions.allClosed;
  };

  editReclamation = (event, { _id }) => {
    const path = this.props.history.location.pathname;
    this.props.history.push(`${path}/${_id}/edit`);
  };

  deleteReclamation = (event, { _id }) => {
    const deletionParams = {
      id: _id,
      callback: () => actions.getReclamations(this.getQuery()),
    };

    const confirmProps = {
      onReady: this.props.deleteReclamation.bind(null, deletionParams),
      text: `${this.props.intl.formatMessage({
        id: 'confirmReclamationDelete',
      })}: ${_id}?`,
      title: this.props.intl.formatMessage({ id: 'deleteReclamation' }),
    };

    this.props.showDialog('CONFIRM_DIALOG', confirmProps);
  };

  getTableData = () => {
    if (this.state.searchResults) {
      return this.state.searchResults.map(this.getDatatableDatum);
    }

    return this.props.reclamations.map(this.getDatatableDatum);
  };

  initializeDatatable = () => {
    return {
      data: this.getTableData(),
      headers: this.getDatatableHeaders(),
      showLoader: this.props.isLoading,
      isLoading: this.props.isLoading,
    };
  };

  goToCreatePage = () => {
    const type = this.props.reclamationType ? 'rewashes' : 'claims';
    const id = this.props.match.params.site;
    this.props.history.push(`/${id}/${type}/create`);
  };

  getButtonText = () => {
    const type = this.props.reclamationType;

    if (type === ReclamationType.Rewash) {
      return this.props.intl.formatMessage({ id: 'addRewash' });
    }

    return this.props.intl.formatMessage({ id: 'addClaim' });
  };

  render() {
    return (
      <section className="reclamations">
        <div className="table-buttons">
          <DateRangeSelector
            dates={[this.props.fromDate, this.props.untilDate]}
            onSubmit={(dates) => {
              this.props.setFromDate(dates.from);
              this.props.setUntilDate(dates.until);
            }}
          />
          <div className="filters-actions-container">
            <FilterSelector
              filters={
                this.props.reclamationType === ReclamationType.Claim
                  ? [
                      { component: StatusFilter, props: { statusFilter: this.props.statusFilter } },
                      { component: TypeFilter, props: { typeFilter: this.props.typeFilter } },
                      {
                        component: ArchivedFilter,
                        props: { archivedFilter: this.props.archivedFilter },
                      },
                    ]
                  : []
              }
              disabled={false}
              isLoading={false}
            />
            <PageActions
              actionFunction={this.goToCreatePage}
              actions={[{ text: this.getButtonText(), permissionKey: viewPermissionRefs.warranty }]}
            />
          </div>
        </div>
        <div className="table-filters">
          <SearchBar
            intl={this.props.intl}
            dataToSearchOn={this.props.reclamations}
            dataToSearchFor={['customerName', 'customerEmail', 'customerPhone']}
            uniqueField="id"
            setSearchMatch={this.setSearchMatch}
            styles={['data-table-search']}
          />
        </div>
        <DataTable
          {...this.initializeDatatable()}
          showLoader={this.props.isLoading}
          // showLoader={true}
          navigationInfo={this.props.navigationInfo}
          paginationInfo={this.getPaginationInfo()}
          fetchSortedData={this.fetchSortedReclamations}
          scrolling
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  const fromDate = selectFromDate(state);
  const untilDate = selectUntilDate(state);
  const apiRequests = selectApiRequests(state);
  const isLoading = get(apiRequests, `${actions.GET_RECLAMATIONS}.isLoading`, false);

  return {
    reclamations: selectReclamations(state),
    multiSites: selectMultiSiteSelection(state),
    siteId: selectSiteExtId(state),
    total: selectTotal(state),
    page: selectPage(state),
    pageSize: selectPageSize(state),
    fromDate,
    untilDate,
    statusFilter: selectStatusFilter(state),
    typeFilter: selectTypeFilter(state),
    archivedFilter: selectArchivedFilter(state),
    dateQuery: {
      fromDate: fromDate.format('YYYY-MM-DD'),
      untilDate: untilDate.format('YYYY-MM-DD'),
    },
    damageClaimOptions: selectReclamationDamageOptions(state),
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      fetchEmployees,
      showDialog,
    },
    dispatch
  );
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(Reclamations);
