import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import DataTable from '../../../core/components/DataTable';
import { getCarPartReports } from '../actions';
import { formatReportTableData } from '../helpers';
import { ReportType } from '../models';
import { selectCarPartReports, selectFromDate, selectUntilDate } from '../reducer';
import { DamageAreaReportPropTypes } from '../types';
import Loader from '../../../core/components/Loader';
import { GET_CAR_PART_REPORTS } from '../actions';
import { getApiStatus } from '../../../core/utils';

const DamageAreaReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();
  const dispatch = useDispatch();
  const fromDate = useSelector(selectFromDate);
  const untilDate = useSelector(selectUntilDate);
  const carPartReports = useSelector(selectCarPartReports);
  const isCarPartReportsLoading = useSelector((state) => getApiStatus(state, GET_CAR_PART_REPORTS));

  useEffect(() => {
    dispatch(getCarPartReports());
  }, []);

  useEffect(() => {
    dispatch(getCarPartReports({ query: { fromDate, untilDate } }));
  }, [fromDate, untilDate]);

  const getColGroup = () => (
    <colgroup>
      <col className="first-column" />
    </colgroup>
  );

  const reportTableDataGetter = () => {
    return formatReportTableData(ReportType.ClaimsByDamageArea, intl.formatMessage, carPartReports);
  };

  const initializeDatatable = () => ({
    ...reportTableDataGetter(),
    addedClass: 'report-table',
    colGroup: getColGroup(),
  });

  return isCarPartReportsLoading ? <Loader /> : <DataTable {...initializeDatatable()} />;
};

export default DamageAreaReport;
