import { isEqual, startsWith } from 'lodash';
import moment from 'moment';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { showDialog } from '../../../core/actions';
import DataTable from '../../../core/components/DataTable';
import PageActions from '../../../core/components/PageActions';
import { hasRoleInOperatorGroup, RoleTypes } from '../../../core/components/Permission';
import TableActionButtonsCell from '../../../core/components/TableActionButtonsCell';
import { addPermissionRefs } from '../../../core/components/UserGroupPermissions';
import { getApiStatus, toString } from '../../../core/utils';
import TodaysTaskInDetail from '../components/TodaysTaskInDetail';
import { selectSession, selectSite } from '../../../store/authReducer';
import { Container, Row, Col } from 'reactstrap';
import { fetchEmployees } from '../../employees/actions';
import { selectEmployees } from '../../employees/reducer';
import { completeTaskAOT } from '../../overview/actions';
import * as actions from '../actions';
import { buildQuery, getLastCompleted } from '../helpers';
import { Interval } from '../models';
import {
  selectPage,
  selectPageSize,
  selectTaskInEdit,
  selectTaskLocations,
  selectTaskPrefixes,
  selectTasks,
  selectTotal,
} from '../reducer';
import { TaskCompletionStatus } from '../types';
import TaskPrefixFilter from './TaskPrefixFilter';
import TaskTypeFilter from './TaskTypeFilter';
import { Drawer, DrawerContainer, DrawerControls } from '../../../core/components/Drawer';
import FilterSelector from '../../../core/components/FilterSelector';

export class Tasks extends Component {
  static propTypes = {
    tasks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    employees: PropTypes.arrayOf(PropTypes.shape({})),
    fetchTasks: PropTypes.func.isRequired,
    showDialog: PropTypes.func.isRequired,
    intl: PropTypes.shape({}).isRequired,
    addTask: PropTypes.func.isRequired,
    fetchEmployees: PropTypes.func.isRequired,
    completeTaskAOT: PropTypes.func.isRequired,
    total: PropTypes.number,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    taskPrefixList: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number,
        message: PropTypes.string,
      })
    ),
    taskLocationList: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.number,
        message: PropTypes.string,
        orderNumber: PropTypes.number,
      })
    ),
    getTaskPrefixes: PropTypes.func,
    getTaskLocations: PropTypes.func,
    session: PropTypes.shape({
      role: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  };

  static defaultProps = { taskPrefixList: [], taskLocationList: [] };

  state = {
    typeFilter: '',
    prefixFilter: '',
    sortQuery: {},
    selectedTask: {},
    toggleCollapse: false,
    taskInEditExpanded: false,
    highlightedRow: 0,
  };

  getTaskQuery = (queryParameters = {}) => {
    const defaultQuery = { page: 0 };

    return buildQuery(defaultQuery, queryParameters, this.state);
  };

  fetchTasks = (queryParameters) => {
    const taskQuery = this.getTaskQuery(queryParameters);
    this.props.fetchTasks(taskQuery);
  };

  shouldComponentUpdate(nextProps, nextState) {
    const arePropsEqual = isEqual(this.props, nextProps);
    const areStatesEqual = isEqual(this.state, nextState);

    return !arePropsEqual || !areStatesEqual;
  }

  componentDidMount() {
    this.fetchTasks();

    this.props.fetchEmployees();

    if (!this.props.taskPrefixList.length) {
      this.props.getTaskPrefixes();
    }

    if (!this.props.taskLocationList.length) {
      this.props.getTaskLocations();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { typeFilter, prefixFilter, sortQuery, selectedTask } = this.state;

    if (
      typeFilter !== prevState.typeFilter ||
      prefixFilter !== prevState.prefixFilter ||
      sortQuery !== prevState.sortQuery
    ) {
      this.fetchTasks();
    }
    if (selectedTask !== prevState.selectedTask) {
      this.setState({ toggleCollapse: false });
    }
  }

  getPaginationInfo() {
    return {
      total: this.props.total,
      page: this.props.page,
      pageSize: this.props.pageSize,
      goToPage: this.getTasksPage,
    };
  }

  getTasksPage = (page) => {
    this.fetchTasks({ page });
  };

  getTaskCompletionText = (task) => {
    return task.interval !== Interval.CarCount
      ? this.props.intl.formatMessage({ id: 'willSkipNextTime' })
      : this.props.intl.formatMessage({ id: 'willUpdateCarCountTask' });
  };

  taskIsValidForPreCompletion = ({ latestCompletion }) => {
    if (latestCompletion) {
      const pending = toString(TaskCompletionStatus, TaskCompletionStatus.Pending);
      if (latestCompletion.status === pending) {
        // Check if the completion is expired
        return moment().utc().isAfter(moment(latestCompletion.scheduleEnd).utc());
      }
    }
    return true;
  };

  handleDone = (event, { _id }) => {
    const task = this.props.tasks.find((t) => t.id === _id);
    this.props.fetchEmployees();

    const dialogProps = {
      onReady: this.props.completeTaskAOT,
      task,
      text: this.getTaskCompletionText(task),
      title: this.props.intl.formatMessage({ id: 'completeTaskAOT' }),
      isValid: this.taskIsValidForPreCompletion(task),
    };

    this.props.showDialog('COMPLETE_TASK_AOT', dialogProps);
  };

  rowClickFunction = (data) => {
    this.setState((state) => ({
      ...state,
      selectedTask: { ...data },
      highlightedRow: data._id,
    }));
  };

  getRowClickInfo = () => ({
    callback: (data) => {
      this.rowClickFunction(data);
    },
  });

  initializeDatatable = (tasks) => {
    const { intl, session } = this.props;

    const data = tasks.map((task) => ({
      _id: task.id,
      _taskId: task.id,
      _showActions: !task.isEssentialTask
        ? true
        : hasRoleInOperatorGroup(session, RoleTypes.OperatorGroupAdmin, task.groupId),
      name: (
        <div>
          <div>{task.name}</div>
          <div className="task-subtitle">
            {task.subTitle && task.subTitle.length > 50
              ? task.subTitle.slice(0, 47) + '...'
              : task.subTitle}
          </div>
        </div>
      ),
      intervalDescription: task.getIntervalDescription(intl.formatMessage),
      start: moment(task.start).format('MM/DD/YYYY'),
      end: task.end && moment(task.end).format('MM/DD/YYYY'),
      lastCompleted: getLastCompleted(task.latestCompletion, this.props.employees),
      _resource: task.resource,
    }));

    const headers = [
      { name: intl.formatMessage({ id: 'name' }), databaseProperty: 'name' },
      { name: intl.formatMessage({ id: 'interval' }) },
      { name: intl.formatMessage({ id: 'startDate' }), databaseProperty: 'start' },
      { name: intl.formatMessage({ id: 'endDate' }), databaseProperty: 'end' },
      { name: intl.formatMessage({ id: 'lastCompleted' }) },
    ];
    headers.push(
      {
        name: this.props.intl.formatMessage({ id: 'resource' }),
        cell: {
          component: (props) => {
            const url = startsWith(props.rowData._resource, 'http')
              ? props.rowData._resource
              : `https://${props.rowData._resource}`;
            return (
              <a href={url} target="_blank" rel="noopener noreferrer">
                {props.rowData._resource && (
                  <i
                    className="icon icon-link px-4 text-info"
                    title={`${props.rowData._resource}`}
                  />
                )}
              </a>
            );
          },
        },
      },
      {
        name: intl.formatMessage({ id: 'action' }),
        cell: {
          component: TableActionButtonsCell,
          mixIns: {
            buttons: [
              {
                title: 'Done',
                class: 'button green small',
                buttonAction: this.handleDone,
              },
            ],
          },
        },
      }
    );

    return {
      data,
      rowClickInfo: this.getRowClickInfo(),
      headers,
      fetchSortedData: this.fetchSortedTasks,
    };
  };

  fetchSortedTasks = (sortParameters) => {
    if (sortParameters) {
      this.setState({ sortQuery: sortParameters });
    }
  };

  setTypeFilter = (filter) => {
    const newFilters = { typeFilter: filter };

    if (filter !== 'Project') {
      newFilters.prefixFilter = '';
    }

    this.setState(newFilters);
  };

  setPrefixFilter = (filter) => {
    this.setState({ prefixFilter: filter });
  };

  removeSelectedTask = () => {
    this.setState((state) => ({ ...state, selectedTask: {}, taskInEditExpanded: false }));
  };

  setSelectedTask = (task) => {
    this.setState({ selectedTask: task });
  };

  setTaskInEditExpanded = () => {
    this.setState({ taskInEditExpanded: !this.state.taskInEditExpanded });
  };

  render() {
    const addDialogProps = {
      title: 'ADD NEW TASK',
      onSubmit: this.props.addTask,
      isNew: true,
    };
    return (
      <Container fluid className="no-padding">
        <Row className="todays-tasks">
          <Col>
            <div className="filters-actions-container">
              <FilterSelector
                filters={[
                  {
                    component: TaskTypeFilter,
                    props: {
                      selectedFilter: this.state.typeFilter,
                      setFilter: this.setTypeFilter,
                    },
                  },
                  this.state.typeFilter === 'Project' && {
                    component: TaskPrefixFilter,
                    props: {
                      selectedFilter: this.state.prefixFilter,
                      setFilter: this.setPrefixFilter,
                    },
                  },
                ].filter(Boolean)}
                disabled={false}
                isLoading={false}
              />

              <PageActions
                actionFunction={this.props.showDialog}
                modalProps={addDialogProps}
                actions={[
                  {
                    dialogType: 'UPSERT_TASK',
                    dialogProps: addDialogProps,
                    text: 'Add Task',
                    permissionKey: addPermissionRefs.task,
                  },
                ]}
              />
            </div>
            <div className="page-action-buttons"></div>
          </Col>
        </Row>
        <DrawerContainer>
          <div>
            <DataTable
              showLoader={this.props.isLoading}
              paginationInfo={this.getPaginationInfo()}
              highlightedRow={this.state.highlightedRow}
              scrolling
              {...this.initializeDatatable(this.props.tasks)}
            />
          </div>
          <Drawer
            expanded={this.state.selectedTask.hasOwnProperty('_id')}
            maximized={this.state.taskInEditExpanded}
          >
            {this.state.selectedTask.hasOwnProperty('_id') && (
              <TodaysTaskInDetail
                {...this.state.selectedTask}
                match={this.props.match}
                history={this.props.history}
                setSelectedTask={this.setSelectedTask}
                removeSelectedTask={this.removeSelectedTask}
                setTaskInEditExpanded={this.setTaskInEditExpanded}
                taskInEditExpanded={this.state.taskInEditExpanded}
              />
            )}
          </Drawer>
        </DrawerContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const isLoading = getApiStatus(state, 'FETCH_TASKS');
  return {
    site: Number(selectSite(state)),
    tasks: selectTasks(state),
    initialTaskInEdit: selectTaskInEdit(state),
    total: selectTotal(state),
    page: selectPage(state),
    pageSize: selectPageSize(state),
    session: selectSession(state),
    employees: selectEmployees(state),
    taskPrefixList: selectTaskPrefixes(state),
    taskLocationList: selectTaskLocations(state),
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...actions,
      showDialog,
      fetchEmployees,
      completeTaskAOT,
    },
    dispatch
  );
};

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withRouter, injectIntl);

export default enhance(Tasks);
